const theme = {
  name: 'Senator-Partners',
  login: {
    typography: {
      useNextVariants: true
    },
    palette: {
      primary: {
        main: '#0079b0',
        light: '#0079b0',
        contrastText: '#666'
      }
    }
  },
  app: {
    typography: {
      useNextVariants: true
    },
    palette: {
      primary: {
        main: '#587daf',
        light: '#89a3c6',
        veryLight: '#bdd2ef',
        contrastText: '#fff'
      },
      secondary: { main: '#587daf' }
    },
    overrides: {
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: '#fff'
        }
      },
      MuiButton: {
        label: {
          color: 'white'
        }
      },
      MuiStepIcon: {
        text: {
          fill: 'white',
          color: 'white'
        }
      },
      MuiTooltip: {
        tooltip: {
          'font-size': '14px',
          'max-width': '500px'
        }
      }
    },
    status: {
      danger: 'orange'
    }
  },
  loginBackground: {
    background: '#747e86'
  },
  loginPaper: {
    background: '#fff'
  },
  appBar: {
    background: '#fff',
    zIndex: '1100',

    candidates: {
      background: 'rgba(88,125,175,0.4)'
    },
    companies: {
      background: 'rgba(136,179,175,0.4)'
    },
    projects: {
      background: 'rgba(197,197,184,0.4)'
    }
  }
};

module.exports = theme;
